import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 60000
  // withCredentials: true // send cookies when cross-domain requests
})

// Request interceptors
service.interceptors.request.use(
  (config) => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    // if (UserModule.token) {
    //   config.headers.token = UserModule.token
    // }
    // if (config.headers.type === 'access') {
    //   config.baseURL = process.env.VUE_APP_ACCESS_API
    //   delete config.headers.type
    // } else {
    //   config.baseURL = process.env.VUE_APP_BASE_API
    // }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const res = response.data
    console.log(res)
    if (res.code !== 0) {
      const msg = res.message
      Message({
        message: msg,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(msg)
    } else {
      return Promise.resolve(res.data)
    }

  },
  (error) => {
    Message({
      message: error.msg || error.toString(),
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
