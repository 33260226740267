import request from './utils/request.js'

export const appointment = (params) => {
  return request( {
    url: '/order',
    method: 'post',
    data: params
  } )
}
export const getAppointmentList=()=> {
  return request( {
    url: '/order/list',
    method: 'post'
  } )
}
